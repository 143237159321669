import React, { FC, ReactElement, useState } from 'react'
import Link from '@components/ui/link'
import { joinClasses, makeClasses } from '@utils/styles'
import { Icon } from '../Icon'
import { externalLink } from '@images/icons'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { Collapse as ReactCollapse } from 'react-collapse'
import { formatStrapiText } from '@utils/methods'
import { Article } from '@services/models/articles.model'

type Props = {
	id: number
	buttonText: string | ReactElement
	content?: string
	link?: string
	linkText?: string
	contentClassWrapper?: string
	onClick?: () => void
	children?: React.ReactNode
	isOpen?: boolean
}

type ClassType = {
	collapse: string
	collapseAction: string
	collapseContent: string
	link: string
	icon: string
	btnLink: string
}

const classes: ClassType = makeClasses({
	collapse: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		'& > *': {
			lineHeight: '20px',
			color: Colors.brown
		},
		'&.active': {
			'& > .button': {
				color: Colors.secondary,
				'&::after': {
					display: 'none'
				}
			}
		}
	},
	collapseAction: {
		position: 'relative',
		cursor: 'pointer',
		margin: 0,
		padding: '15px 0 15px 30px',
		fontSize: '16px',
		color: Colors.lightBlack,
		'&::before': {
			content: '""',
			position: 'absolute' as 'absolute',
			top: '23px',
			left: 0,
			width: '14px',
			height: '2px',
			background: Colors.secondary
		},
		'&::after': {
			content: '""',
			position: 'absolute' as 'absolute',
			top: '23px',
			left: 0,
			width: '14px',
			height: '2px',
			background: Colors.secondary,
			transform: 'rotate(90deg)'
		}
	},
	collapseContent: {
		fontSize: '14px',
		fontWeight: 400,
		paddingLeft: '30px',
		'& ul': {
			marginBottom: '0px !important'
		}
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '14px',
		margin: '15px 0',
		color: Colors.secondary,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	icon: {
		width: '20px',
		height: '20px'
	},
	btnLink: {
		...ButtonStyle,
		border: 0,
		fontSize: '14px',
		margin: '15px 0',
		color: `${Colors.secondary} !important`,
		display: 'flex',
		padding: 0,
		borderColor: `transparent !important`,
		background: `transparent !important`,
		'&:hover': {
			textDecoration: 'underline'
		}
	}
})

const Collapse: FC<Props> = ({
	id,
	buttonText,
	content,
	link,
	linkText,
	contentClassWrapper,
	onClick,
	children,
	isOpen
}) => {
	const [open, setOpen] = useState<boolean>(!!isOpen)
	const [tabIndex, setTabIndex] = useState<number>(-1)

	const collapseId = `collapse-${id}`
	const collapseContentId = `collapse-content-${id}`
	const collapseButtonId = `collapse-button-${id}`

	const displayLink = () => {
		if (onClick) {
			return (
				<button className={classes.btnLink} type="button" onClick={onClick}>
					{formatStrapiText(linkText)}{' '}
					<Icon src={externalLink} className={classes.icon} />
				</button>
			)
		}

		return (
			<Link to={formatStrapiText(link) || ''} className={classes.link}>
				{formatStrapiText(linkText)}{' '}
				<Icon src={externalLink} className={classes.icon} />
			</Link>
		)
	}

	const onClickActionCollapse = () => () => {
		const doAction = !open

		setTabIndex(doAction ? 0 : -1)
		setOpen(doAction)
	}

	return (
		<div
			id={collapseId}
			className={joinClasses([classes.collapse, open ? 'active' : ''])}
		>
			<div
				id={collapseButtonId}
				className={joinClasses([classes.collapseAction, 'button'])}
				tabIndex={0}
				aria-expanded={open}
				aria-controls={collapseContentId}
				role="button"
				onClick={onClickActionCollapse()}
			>
				{typeof buttonText === 'string'
					? formatStrapiText(buttonText)
					: buttonText}
			</div>
			<ReactCollapse isOpened={open}>
				<div
					id={collapseContentId}
					className={classes.collapseContent}
					role="region"
					tabIndex={tabIndex}
				>
					<div className={contentClassWrapper}>
						{formatStrapiText(content)}
						{(link || onClick) && linkText && displayLink()}
						{children}
					</div>
				</div>
			</ReactCollapse>
		</div>
	)
}

export default Collapse
