import {
	Exclude,
	Expose,
	instanceToPlain,
	plainToInstance,
	Transform
} from 'class-transformer'
import {
	IActivityLogItemDTO,
	IActivityLogItemRequestDTO,
	IActivityLogItemStatusDTO,
	IApplicantDTO,
	IDistrictDTO,
	ILocationDTO,
	ITeamMemberDTO
} from '@services/dtos/_miscellaneous.dto'
import assert from 'assert'
import {
	DistrictRepository,
	RedesignWebPortalRepository,
	TeamMembersRepository,
	WaterCounterAddressesRepository
} from '@services/repositories/_miscellaneous.repository'
import {
	ITeamMember,
	ITeamMembersRepository,
	IActivityLogItem,
	IActivityLogItemRequest,
	IActivityLogItemStatus,
	IApplicant,
	IDistrict,
	IDistrictRepository,
	IWaterCounterAddresses,
	IWaterCounterAddressDto,
	IRedesignWebPortal,
	IRedesignWebPortalDto
} from '@services/types'
import config from '@utils/config'
import { CustomDate } from '@services/models/shared.model'

export class TeamMember implements ITeamMember {
	@Expose({ name: 'elected_team_id' })
	id: string = ''

	@Expose({ name: 'name' })
	name: string = ''

	@Exclude()
	_repository: ITeamMembersRepository

	constructor(dto?: ITeamMemberDTO) {
		if (dto) {
			assert(
				dto.elected_team_id && dto.name,
				'Can not instanciate an elected team member without id and name '
			)

			this.parse(dto)
		}

		this._repository = new TeamMembersRepository()
	}

	@Exclude()
	public parse(dto: ITeamMemberDTO) {
		Object.assign(this, plainToInstance(TeamMember, dto))
	}

	@Exclude()
	static serialize(model: TeamMember): ITeamMemberDTO {
		return instanceToPlain(model) as ITeamMemberDTO
	}

	// TODO: define Enum for tyoe
	@Exclude()
	static async fetchAllByAsync(
		type: string = 'electedTeamMembers'
	): Promise<TeamMember[]> {
		if (type !== 'electedTeamMembers') {
			throw new Error('NotImplemented')
		}

		const repository = new TeamMembersRepository()
		const dtos: ITeamMemberDTO[] =
			await repository.getElectectedTeamMembersAsync()

		const models: TeamMember[] = dtos.map((dto) => new TeamMember(dto))

		return models
	}

	@Exclude()
	static getLocalElectedTeamMembers(): TeamMember[] {
		// TODO: put this logic to the db
		let localMembers = [] as TeamMember[]

		// TODO: instead of getting this from localstorage, try to define it when building Gatsby
		// TODO: use redux-toolkit
		const localValue: string | null = localStorage.getItem(
			config.localStorage.electedTeamMembersKey
		)

		if (localValue) {
			try {
				localMembers = (JSON.parse(localValue) as ITeamMemberDTO[]).map(
					(dto) => !!dto && new TeamMember(dto)
				)
			} catch (error) {
				// TODO: use redux-toolkit instead
				localStorage.setItem(
					config.localStorage.electedTeamMembersKey,
					JSON.stringify([])
				)

				console.error('Error while reading local values. May be corrupted')
			}
		}

		return localMembers || []
	}

	@Exclude()
	static async getElectedTeamMembersAsync(): Promise<TeamMember[]> {
		let localMembers = TeamMember.getLocalElectedTeamMembers()

		// TODO: Apply parsing later
		if (!localMembers || localMembers.length < 1) {
			localMembers = await TeamMember.fetchAllByAsync()

			// TODO: Clean up on logout :)
			localStorage.setItem(
				config.localStorage.electedTeamMembersKey,
				JSON.stringify(localMembers.map(TeamMember.serialize))
			)
		}

		return localMembers
	}
}

export class ActivityLogItemRequest implements IActivityLogItemRequest {
	@Expose({ name: 'incident_id' })
	id: string = ''

	@Expose({ name: 'incident_type_id' })
	typeId: string = ''

	@Expose({ name: 'ticket_number' })
	code: string = ''

	@Expose({ name: 'incident_form_id' })
	formId: string = ''

	@Expose({ name: 'form_type_id' })
	formTypeId: string = ''

	constructor(dto: IActivityLogItemRequestDTO) {
		this.parse(dto)
	}

	public parse(dto: IActivityLogItemRequestDTO) {
		Object.assign(this, plainToInstance(ActivityLogItemRequest, dto))
	}
}

export class ActivityLogItemStatus implements IActivityLogItemStatus {
	@Expose({ name: 'value' })
	id: string = ''

	@Expose({ name: 'value_label' })
	name: string = ''

	constructor(dto: IActivityLogItemStatusDTO) {
		this.parse(dto)
	}

	public parse(dto: IActivityLogItemStatusDTO) {
		Object.assign(this, plainToInstance(ActivityLogItemStatus, dto))
	}
}

export class ActivityLogItem implements IActivityLogItem {
	@Exclude()
	private _baseURL = config.request.baseUrl

	@Expose({ name: 'incident' })
	@Transform(({ value }) => new ActivityLogItemRequest(value), {
		toClassOnly: true
	})
	private _incident?: ActivityLogItemRequest

	@Expose({ name: 'date' })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	dateAdd?: CustomDate

	@Expose({ name: 'value_change' })
	@Transform(({ value }) => new ActivityLogItemStatus(value), {
		toClassOnly: true
	})
	private _status?: ActivityLogItemStatus

	// communication?: string | undefined = undefined

	constructor(dto?: IActivityLogItemDTO) {
		if (dto) {
			this.parse(dto)
		}
	}

	get link(): string {
		return `${this._baseURL}/${this.requestId}`
	}

	get requestId(): string {
		return `${this._incident?.id}`
	}

	get requestTypeId(): string {
		return `${this._incident?.typeId}`
	}

	get code(): string {
		return `${this._incident?.code}`
	}

	get statusName(): string {
		return `${this._status?.name}`
	}

	get statusId(): string {
		return `${this._status?.id}`
	}

	@Exclude()
	public parse(dto: IActivityLogItemDTO) {
		Object.assign(this, plainToInstance(ActivityLogItem, dto))
	}

	@Exclude()
	static serialize(model: ActivityLogItem): IActivityLogItemDTO {
		return instanceToPlain(model) as IActivityLogItemDTO
	}
}

export class Applicant implements IApplicant {
	@Expose({ name: 'address' })
	address: string = ''

	@Expose({ name: 'appartment' })
	apartment: string = ''

	@Expose({ name: 'city' })
	city: string = ''

	@Expose({ name: 'email' })
	email: string = ''

	@Expose({ name: 'first_name' })
	firstName: string = ''

	@Expose({ name: 'last_name' })
	lastName: string = ''

	@Expose({ name: 'postal_code' })
	postalCode: string = ''

	@Expose({ name: 'signature_acceptance' })
	signatureAcceptance: boolean = false

	@Expose({ name: 'signature_date' })
	signatureDate: string = ''

	@Expose({ name: 'telephone' })
	telephone: string = ''

	@Expose({ name: 'telephone_evening' })
	telephone_evening: string = ''

	@Expose({ name: 'telephone_other' })
	telephone_other: string = ''

	constructor(dto: IApplicantDTO) {
		this.parse(dto)
	}

	public parse(dto: IApplicantDTO) {
		Object.assign(this, plainToInstance(Applicant, dto))
	}

	@Exclude()
	static serialize(Applicant: IApplicant): IApplicantDTO {
		const dto = instanceToPlain(Applicant)

		return dto as IApplicantDTO
	}
}

export class ElectorialDistrict implements IDistrict {
	@Expose({ name: 'electoral_district_id' })
	id: string = ''

	@Expose({ name: 'place_name' })
	place_name: string = ''

	@Exclude()
	_repository: IDistrictRepository

	constructor(dto?: IDistrictDTO) {
		if (dto) {
			assert(
				dto.id && dto.place_name,
				'Can not instanciate district without id and name'
			)

			this.parse(dto)
		}

		this._repository = new DistrictRepository()
	}

	@Exclude()
	public parse(dto: IDistrictDTO) {
		Object.assign(this, dto)
	}

	@Exclude()
	static serialize(model: ElectorialDistrict): IDistrictDTO {
		return instanceToPlain(model) as IDistrictDTO
	}

	// TODO: define Enum for tyoe
	@Exclude()
	static async fetchAllByAsync(
		type: string = 'electorialDistricts'
	): Promise<ElectorialDistrict[]> {
		if (type !== 'electorialDistricts') {
			throw new Error('NotImplemented')
		}

		const repository = new DistrictRepository()
		const dtos: IDistrictDTO[] = await repository.getDistrictsAsync()

		const models: ElectorialDistrict[] = dtos.map(
			(dto) =>
				new ElectorialDistrict({
					id: dto.properties.electoral_district_id,
					place_name: dto.place_name
				} as IDistrictDTO)
		)

		return models
	}

	@Exclude()
	static getLocalElectorialDistrict(): ElectorialDistrict[] {
		// TODO: put this logic to the db
		let localDistricts = [] as ElectorialDistrict[]

		// TODO: instead of getting this from localstorage, try to define it when building Gatsby
		// TODO: use redux-toolkit
		const localValue: string | null = localStorage.getItem(
			config.localStorage.electorialDistrictsKey
		)

		if (localValue) {
			try {
				localDistricts = (JSON.parse(localValue) as IDistrictDTO[]).map(
					(dto) => !!dto && new ElectorialDistrict(dto)
				)
			} catch (error) {
				// TODO: use redux-toolkit instead
				localStorage.setItem(
					config.localStorage.electorialDistrictsKey,
					JSON.stringify([])
				)

				console.error('Error while reading local values. May be corrupted')
			}
		}

		return localDistricts || []
	}

	@Exclude()
	static async getElectorialDistrictsAsync(): Promise<ElectorialDistrict[]> {
		let localDistricts = ElectorialDistrict.getLocalElectorialDistrict()
		// TODO: Apply parsing later
		if (!localDistricts || localDistricts.length < 1) {
			localDistricts = await ElectorialDistrict.fetchAllByAsync()

			// TODO: Clean up on logout :)
			localStorage.setItem(
				config.localStorage.electorialDistrictsKey,
				JSON.stringify(localDistricts.map(ElectorialDistrict.serialize))
			)
		}

		return localDistricts
	}
}

export class WaterCounterAddresses implements IWaterCounterAddresses {
	@Exclude()
	_repository: IWaterCounterAddresses

	constructor() {
		this._repository = new WaterCounterAddressesRepository()
	}

	@Exclude()
	async getAddressesAsync(civicId: string): Promise<IWaterCounterAddressDto[]> {
		const result = await this._repository.getAddressesAsync(civicId)
		return result
	}

	async getAddressesDuplexQuadruplexAsync(
		civicId: string,
		postalCode: string
	): Promise<IWaterCounterAddressDto[]> {
		const result = await this._repository.getAddressesDuplexQuadruplexAsync(
			civicId,
			postalCode
		)
		return result
	}
}

export class RedesignWebPortal implements IRedesignWebPortal {
	@Exclude()
	_repository: IRedesignWebPortal

	constructor() {
		this._repository = new RedesignWebPortalRepository()
	}

	@Exclude()
	async getRedesignWebPortalHtml(
		pageId: string
	): Promise<IRedesignWebPortalDto[]> {
		const result = await this._repository.getRedesignWebPortalHtml(pageId)
		return result
	}
}
