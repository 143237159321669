import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Cookies from 'js-cookie'

export const classes: {
	consentWraper: string
	consentBanner: string
	collapse: string
	flexContainer: string
	textContainer
	buttonContainer: string
	link: string
	checkboxWrapper: string
	checkBox: string
	checkboxChecked: string
	checkBoxLabel: string
} = makeClasses({
	consentWraper: {
		background: '#000',
		backgroundColor: 'rgba(0, 0, 0, .4)',
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		'z-index': 49999
	},
	consentBanner: {
		backgroundColor: '#e6e7e8',
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		'z-index': 50000
	},
	collapse: {
		paddingTop: '20px',
		'& > div': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	flexContainer: {
		display: 'flex',
		'justify-content': 'center',
		margin: 'auto',
		width: '976px',
		paddingTop: '25px',
		flexDirection: 'column',

		[Breakpoints.minWidth('xxl')]: {
			flexDirection: 'row'
		},
		[Breakpoints.maxWidth('lg')]: {
			width: 'auto',
			padding: '15px'
		}
	},
	textContainer: {
		flex: '0 0 calc(66.6666%)'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		[Breakpoints.minWidth('xxl')]: {
			flex: '0 0 auto',
			marginTop: '30px'
		},
		'& > a': {
			[Breakpoints.maxWidth('lg')]: {
				margin: '0'
			}
		}
	},
	link: {
		textDecoration: 'none',
		cursor: 'pointer',
		color: Colors.secondary,
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	checkboxWrapper: {
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '10px 12px',
		'& > span': {
			background: Colors.white
		}
	},
	checkBox: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '16px',
		height: '16px',
		marginRight: '10px',
		border: `1px solid ${Colors.lightGrey3}`
	},
	checkBoxChecked: {
		position: 'relative',
		'&::before': {
			content: '""',
			width: '100%',
			maxWidth: '10px',
			height: '10px',
			marginLeft: '2px',
			background: Colors.secondary
		}
	},
	checkBoxLabel: {
		alignItems: 'center',
		display: 'inline-flex',
		justifyContent: 'space-between',
		width: '100%',
		'& b': { flex: '30' },
		'& span': {
			display: 'flex',
			flexDirection: 'row-reverse',
			color: Colors.lightGreen
		}
	}
})

const Buffer = require('buffer').Buffer

const COOKIE_NAME = 'cookies-consent'

export const setSessionConsent = () => {
	const base64Cookie = Buffer.from('true').toString('base64')
	Cookies.set(COOKIE_NAME, base64Cookie, { expires: 30 })
}

export const getSessionConsent = () => {
	const base64Cookie = Cookies.get(COOKIE_NAME) || ''
	return Buffer.from(base64Cookie, 'base64').toString()
}
